<template>
  <div class="decoration">
    <Banner :list="banners"></Banner>
    <div class="decoration-main container">
		<menus></menus>
      <div class="serveRange">
        <div class="main-title">
          <h1>服务范围</h1>
          <h3>已累计为10万+建企提供服务</h3>
        </div>
        <div class="list">
          <div class="item outslide">
            <img src="@/assets/image/decoration/818.png" alt="" />
            <p>劳务资质办理</p>
          </div>
          <div class="item slide1">
            <img src="@/assets/image/decoration/819.png" alt="" />
            <p>人员证书培训、延续</p>
          </div>
          <div class="center">
            <div class="item">
              <img src="@/assets/image/decoration/820.png" alt="" />
              <p>
                承装(修、试)<br />
                电力设施许可证新办
              </p>
            </div>
            <div class="item">
              <img src="@/assets/image/decoration/821.png" alt="" />
              <p>
                承装(修、试)<br />
                电力设施许可证生级
              </p>
            </div>
            <div class="item">
              <img src="@/assets/image/decoration/822.png" alt="" />
              <p>电器施工设备与电力相关资质办理</p>
            </div>
          </div>
          <div class="item slide2">
            <img src="@/assets/image/decoration/823.png" alt="" />
            <p>安全生产许可证办理</p>
          </div>
        </div>
        <div class="m-list">
          <div class="top">
            <div class="item">
              <img src="@/assets/image/decoration/818.png" alt="" />
              <p>劳务资质办理</p>
            </div>
            <div class="item">
              <img src="@/assets/image/decoration/819.png" alt="" />
              <p>人员证书培训、延续</p>
            </div>
          </div>
          <div class="bottom">
            <div class="slide-l">
              <div class="item">
                <img src="@/assets/image/decoration/823.png" alt="" />
                <p>安全生产许可证办理</p>
              </div>
              <div class="item">
                <img src="@/assets/image/decoration/822.png" alt="" />
                <p>电器施工设备与电力相关资质办理</p>
              </div>
            </div>
            <div class="slide-r">
              <div class="item">
                <img src="@/assets/image/decoration/820.png" alt="" />
                <p>
                  承装(修、试)<br />
                  电力设施许可证新办
                </p>
              </div>
              <div class="item">
                <img src="@/assets/image/decoration/821.png" alt="" />
                <p>
                  承装(修、试)<br />
                  电力设施许可证生级
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
	  <passage></passage>
      <!-- <connect></connect> -->
	</div>
	
	<scenes></scenes>
	
	<videolist ifcolor='aa' :fromData="fromData"></videolist>
	
	
	<div class="decoration-main container py-6" >
      <div class="required">
        <div class="text-title">
          <h1>服务流程</h1>
          <h3>标准化服务，贴心建企业务专家</h3>
        </div>
        <div class="require-main">
          <div class="line"></div>
          <div class="list">
            <div class="item">
              <div class="img-box">
                <div class="img"></div>
              </div>
              <div class="info">
                <h3>免费咨询洽谈</h3>
                <p>专业服务人员沟通， 确认您的具体需求及 服务类型</p>
              </div>
            </div>
            <div class="item">
              <div class="img-box">
                <div class="img"></div>
              </div>
              <div class="info">
                <h3>达成合作服务</h3>
                <p>根据您的具体需求，为 您的项目量身定制服务 方案</p>
              </div>
            </div>
            <div class="item">
              <div class="img-box">
                <div class="img"></div>
              </div>
              <div class="info">
                <h3>基础资料准备</h3>
                <p>
                  协助您准备项目申报所 需的相应材料、人员资 料，提交有关部门
                </p>
              </div>
            </div>
            <div class="item">
              <div class="img-box">
                <div class="img"></div>
              </div>
              <div class="info">
                <h3>服务提交审核</h3>
                <p>放心等待审批，剩下 的事情交给我们就好</p>
              </div>
            </div>
            <div class="item">
              <div class="img-box">
                <div class="img"></div>
              </div>
              <div class="info">
                <h3>通过审核 下发证书</h3>
                <p>成功获取证书，工程 项目顺利落实</p>
              </div>
            </div>
          </div>
        </div>
      </div>
	</div>
	
	<div class="mt-3 py-1" style="background: #FAFAFB;">
		<strengthnew></strengthnew>
	</div>

     <!-- <div class="address">
        <div class="main-title">
          <h1>服务地域</h1>
          <h3>专业服务网点遍布全国30+主要城市中心地带</h3>
        </div>
        <div class="address-main p-2">
          <div
            class="address-bg"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/image/decoration/all.jpg') + ')',
            }"
          >
            <img src="@/assets/image/decoration/all.jpg" alt="" />
          </div>
          <div class="address-content">
            <p class="title mt-1">我们服务的地域：覆盖全国</p>
            <div class="list">
              <template v-for="(item, index) in address">
                <div class="item" :key="index">
                  <a
                    :href="$mobile ? item.m_domain : item.domain"
                    target="_blank"
                  >
                    {{ item.area_name }}
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div> -->
     <!-- <div class="sucess">
        <div class="sucess-main">
          <div class="project">
            <div class="top">
              <div class="title">
                <h3 class="name">成功案例</h3>
                <p class="sname">PROJECT</p>
              </div>
              <router-link to="/case">more+</router-link>
            </div>
            <div class="list my-2">
              <div class="hot">
                <div>
                  <div class="img-box">
                    <img src="@/assets/image/project/news.jpg" alt="" />
                  </div>
                  <div
                    v-if="caseList.length > 0"
                    class="item"
                    @click="toArticle(caseList[0].id, caseList[0].flag)"
                  >
                    <h3 class="title">
                      <span>01</span>{{ caseList[0].title }}
                    </h3>
                    <p class="desc">{{ caseList[0].guide }}</p>
                  </div>
                </div>
              </div>
              <div class="normal">
                <template v-for="(item, index) in caseList">
                  <div
                    class="item"
                    v-if="index > 0"
                    @click="toArticle(item.id, item.flag)"
                    :key="index"
                  >
                    <h3 class="title">
                      <span>0{{ index + 1 }}</span
                      >{{ item.title }}
                    </h3>
                    <p class="desc">{{ item.guide }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="answer">
            <div class="top">
              <div class="title">
                <h3 class="name">常见问题</h3>
                <p class="sname">QUESTIONS</p>
              </div>
              <router-link class="more" to="/">more+</router-link>
            </div>
            <div class="list my-2">
              <template v-for="(item, index) in questions">
                <router-link :key="index" to="/article?id=57">{{
                  item
                }}</router-link>
              </template>
            </div>
          </div>
        </div>
      </div> -->
      <choose ifcolor='show'></choose>
	  <news></news>
	  
	  <strength ifcolor='show'></strength> 

	<!-- <message></message> -->

  </div>
</template>

<script>
import Banner from "@/components/common/banner";
import passage from "@/components/common/passage";
// import message from "@/components/common/message";
import choose from "@/components/common/choose";
import strength from "@/components/common/strength";
import connect from "@/components/common/connect";
import scenes from '@/components/common/service/scenes.vue'
export default {
  components: {
    Banner,
    passage,
    // message,
    choose,
    strength,
    connect,
	scenes
  },
  data() {
    return {
	  fromData:{subdoadmin: '',launchPage: 50,classify: 20,scope:0},
      caseList: [],
      banners: {
        title: "承装修试 劳务不分等级资质办理",
        desc: "办理周期短 通过率高 省时省心省力",
        list: [],
      },
      questions: [
        "资质怎么办理？",
        "办一个资质多少钱？",
        "办理一个资质需要多久？",
        "办理资质我们需要什么条件？",
        "办资质我能接什么工程？",
        "申请建筑业企业资质，多长时间能知道审批结果？",
        " 净资产如何认定？",
        " 已具有设计或施工资质的企业，可以申请什么级别的设计施工一体化资质？",
        " 已具有设计或施工资质的企业，可以申请什么级别的设计施工一体化资质？",
        " 已具有设计或施工资质的企业，可以申请什么级别的设计施工一体化资质？",
      ],
      address: [],
      serveList: [
        {
          name: "建筑装修装饰工程",
          desc: "承担各类建筑装修装饰工程 及其直接配套的工程设施",
          img: require("@/assets/image/project/201.png"),
        },
        {
          name: "建筑幕墙工程",
          desc: "承担各类型的建筑幕墙工程的施工",
          img: require("@/assets/image/project/202.png"),
        },

        {
          name: "钢筋结构工程",
          desc: "承担各类钢结构工程的施工",
          img: require("@/assets/image/project/203.png"),
        },
        {
          name: "基础地基工程",
          desc: "承担各类地基基础工程的施工",
          img: require("@/assets/image/project/204.png"),
        },
        {
          name: "建筑机电安装工程",
          desc: "承担各类建筑工程项目的设备、 线路、管道的安装",
          img: require("@/assets/image/project/205.png"),
        },
        {
          name: "消防设施工程",
          desc: "承担各类型消防设施工程的施工",
          img: require("@/assets/image/project/206.png"),
        },
        {
          name: "电子智能化工程",
          desc: "承担各类型电子工程、 建筑智能化工程施工",
          img: require("@/assets/image/project/207.png"),
        },
        {
          name: "城市及道路照明工程",
          desc: "当前资质简介，副标题副标题",
          img: require("@/assets/image/project/212.png"),
        },
        {
          name: "环保工程",
          desc: "副标题副标题",
          img: require("@/assets/image/project/208.png"),
        },
        {
          name: "防水防腐保温工程",
          desc: "副标题副标题",
          img: require("@/assets/image/project/209.png"),
        },
        {
          name: "模板脚手架工程",
          desc: "副标题副标题",
          img: require("@/assets/image/project/210.png"),
        },
        {
          name: "公路路基工程",
          desc: "副标题副标题",
          img: require("@/assets/image/project/211.png"),
        },
      ],
      chooseMenu: [
        {
          name: "价格公开透明",
          sname: "The price is open and transparent",
          content: [],
        },
        {
          name: "企业专属人才库",
          sname: "Enterprise exclusive talent pool",
        },
        {
          name: "流程明确、办理周期短",
          sname: "Clear process and short processing cycle",
        },
        {
          name: "专业顾问团队",
          sname: "Clear process and short processing cycle",
        },
      ],
    };
  },
  methods: {
    getFilialeList() {
      this.$api.getFilialeList().then((res) => {
        this.address = res.data.list;
      });
    },
    getBannerList() {
      this.$api
        .getBannerList({
          // 10首页  20
          launchPage: 50,
          launchFacility: 10,
        })
        .then((res) => {
          this.banners.list = res.data.list;
        });
    },
    toArticle(id, flag) {
      this.$router.push({
        name: "article",
        query: {
          id: id,
          flag: flag,
        },
      });
    },
    getCaseList() {
      this.$api
        .getArticleList({
          flag: "case",
          limit: 4,
        })
        .then((res) => {
          this.caseList = res.data.list;
        });
    },
  },
  created() {
    this.getBannerList();
    this.getCaseList();
    this.getFilialeList();
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "HYYaKuHeiW";
  src: url(../../assets/font/HYYaKuHeiW.ttf);
  font-weight: normal;
  font-style: normal;
}
@import "@/assets/css/decoration.scss";
@import "@/assets/css/contract.scss";
</style>
